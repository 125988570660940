import { useUser } from "hooks/UserContext";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
// import { setTokens } from "redux-modules/auth";
// import { setUsername } from "redux-modules/info";
import { setLogin } from "redux-modules/auth";
import { setInfo } from "redux-modules/info";
import { useGetOAuth2TokenMutation } from "services/auth";

const CallbackPage = () => {
  const [getOAuth2Token, { isLoading: isLoginLoading }] = useGetOAuth2TokenMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code") ?? "";

  const [errorMessage, setErrorMessage] = useState<string>("");

  const isMounted = useRef<boolean>(false);

  const { registerUser } = useUser();

  const setupTokens = useCallback(async () => {
    const loginResponse = await getOAuth2Token({
      code,
      redirect_uri: `${window.location.origin}${window.location.pathname}`,
    }).unwrap();

    if (loginResponse.AuthenticationResult === undefined) {
      setErrorMessage("Unexpected error");
      return;
    }

    const { AccessToken, IdToken, RefreshToken } = loginResponse.AuthenticationResult;
    const { role } = loginResponse;

    dispatch(setInfo({ username: "Azure", role }));
    dispatch(
      setLogin({
        accessToken: AccessToken,
        idToken: IdToken,
        refreshToken: RefreshToken,
        authType: "Azure",
      })
    );
    navigate("/");
  }, [code, dispatch, navigate /* registerUser */, getOAuth2Token]);

  useEffect(() => {
    if (isMounted.current) return;

    isMounted.current = true;
    setupTokens();
  }, [setupTokens]);

  return (
    <div>
      <h1>CallbackPage</h1>
      <p>{errorMessage}</p>
    </div>
  );
};

export default CallbackPage;
