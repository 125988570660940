import {
  Menu as MenuIcon,
  Person as AccountIcon,
  ArrowBack as ArrowBackIcon,
} from "@mui/icons-material";
import { AppBar, Toolbar, IconButton, Typography, Menu, MenuItem } from "@mui/material";
import { MfaSettingDialog } from "components/molecular/MfaSettingDialog";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authState } from "redux-modules/auth";

import { useLayout } from "../../hooks/LayoutContext";

import type { FC } from "react";

export const Header: FC = () => {
  const { isSidebarOpened, toggleSidebar } = useLayout();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const { authType } = useSelector(authState);
  const [mfaSettingOpen, setMfaSettingOpen] = useState<boolean>(false);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMfaSettingClick = () => {
    handleProfileMenuClose();
    setMfaSettingOpen(true);
  };

  const handleLogoutClick = () => {
    handleProfileMenuClose();
    navigate("/logout");
  };

  return (
    <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, pr: 0 }}>
      <Toolbar sx={{ backgroundColor: "#303030" }}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={toggleSidebar}
          edge="start"
          size="large"
        >
          {isSidebarOpened ? <ArrowBackIcon /> : <MenuIcon />}
        </IconButton>
        <Typography variant="h6" sx={{ ml: "1rem" }}>
          Agile Data Collector ({process.env.REACT_APP_DISPLAY_ENV_NAME})
        </Typography>
        <IconButton
          id="profile-icon-button"
          aria-controls={menuOpen ? "profile-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={menuOpen ? "true" : undefined}
          size="large"
          onClick={handleProfileMenuOpen}
          color="inherit"
          sx={{ ml: "auto" }}
        >
          <AccountIcon />
        </IconButton>
        <Menu
          id="profile-menu"
          anchorEl={anchorEl}
          open={menuOpen}
          onClose={handleProfileMenuClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          MenuListProps={{ "aria-labelledby": "profile-icon-button" }}
        >
          <MenuItem onClick={handleMfaSettingClick} disabled={authType! !== "ADC"}>
            MFA Setting
          </MenuItem>
          <MenuItem onClick={handleLogoutClick}>Logout</MenuItem>
        </Menu>
        <MfaSettingDialog open={mfaSettingOpen} onClose={() => setMfaSettingOpen(false)} />
      </Toolbar>
    </AppBar>
  );
};
