import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";

import type { FetchArgs } from "@reduxjs/toolkit/query/react";
import type { RootState } from "store";

const MAIN_API_URL = process.env.REACT_APP_BACKEND_ENDPOINT;

const createBaseQuery = () =>
  fetchBaseQuery({
    baseUrl: MAIN_API_URL,
    timeout: 29000,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.idToken;
      if (token) {
        headers.set("authorization", token);
      }
      return headers;
    },
  });

const baseQuery = retry(
  async (args: string | FetchArgs, api, extraOptions) => {
    const result = await createBaseQuery()(args, api, extraOptions);

    if (result.error?.status === 401 || result.error?.status === 403) {
      retry.fail(result.error);
    }

    return result;
  },
  { maxRetries: 0 }
);

export const mainApi = createApi({
  baseQuery,
  reducerPath: "mainApi",
  endpoints: () => ({}),
  tagTypes: [
    "Job",
    "ProcessResult",
    "PE",
    "Workflow",
    "File",
    "MailTemplate",
    "Master",
    "ReportFilter",
    "MfaSetting",
  ],
  keepUnusedDataFor: 0,
  // refetchOnReconnect: true,
});
