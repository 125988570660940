import { Container } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import AdcSignInEntry from "./components/AdcSignInEntry";
import CorpSignInEntry from "./components/CorpSignInEntry";

const SignInRouterPage = () => (
  <Container component="main" fixed sx={{ my: 2, py: 1, maxWidth: 760 }}>
    <Grid container sx={{ my: 3, p: 2, display: "flex", alignItems: "stretch" }}>
      <Grid xs={12} sx={{ p: 1, display: "flex", alignItems: "stretch" }}>
        <b>This environment is for {process.env.REACT_APP_DISPLAY_ENV_NAME}</b>
      </Grid>
      <Grid xs={12} sm={6} sx={{ p: 1, display: "flex", alignItems: "stretch" }}>
        <CorpSignInEntry />
      </Grid>
      <Grid xs={12} sm={6} sx={{ p: 1, display: "flex", alignItems: "stretch" }}>
        <AdcSignInEntry />
      </Grid>
    </Grid>
  </Container>
);

export default SignInRouterPage;
